.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.photo__container {
  height: 200px;
  width: 200px;
  overflow: hidden;
  border-radius: 200px;
  background: url("./shashank-duhan.jpg") center center no-repeat;
  background-size: cover;
}

@media (prefers-reduced-motion: no-preference) {
  .photo__container {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  width: 100vw;
}

.block {
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

canvas {
  background-color: lightgrey;
}
