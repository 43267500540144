

.projects-list-container {
    display: flex;
    max-width: 100vw;
    flex-wrap: wrap;
}

.thumbnail {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 4px;
    border: 1px solid white;
    display: inline-flex;
    margin: 20px;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
}

.link {
    margin: 0 auto;
}