/* * {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
} */
:root {
    --hue: 210;
    --bg: #ddd5d5;
    --fg: #2a2222;
    --red: #b31a42;
    --white: #fff;
    --blue: #0a3161;
    --trans-dur: 0.3s;
    font-size: clamp(1rem, 1.2rem, 1.25rem);
}
body {
    background-color: var(--bg);
    color: var(--fg);
    display: flex;
    font: 1em/1.5 sans-serif;
    height: 100vh;
    transition: background-color var(--trans-dur), color var(--trans-dur);
}
.pl {
    --dur: 3s;
    display: block;
    margin: auto;
    width: 14em;
    height: auto;
}
.pl__ball, .pl__ball-shadow, .pl__ball-texture, .pl__stripe, .pl__stripe-dot, .pl__stripe-rotate {
    animation-duration: var(--dur);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.pl__ball {
    animation-name: ball;
}
.pl__ball-shadow {
    animation-name: ball-shadow;
}
.pl__ball-texture {
    animation-name: ball-texture;
}
.pl__stripe {
    animation-name: stripe;
}
.pl__stripe--1 {
    animation-name: stripe1;
}
.pl__stripe--2 {
    animation-name: stripe2;
}
.pl__stripe--3 {
    animation-name: stripe3;
}
.pl__stripe-dot {
    animation-name: stripe-dot;
}
.pl__stripe-dot-group:nth-child(1) .pl__stripe-dot:nth-child(2) {
    animation-delay: calc(var(--dur) * 0.2333333333);
}
.pl__stripe-dot-group:nth-child(1) .pl__stripe-dot:nth-child(3) {
    animation-delay: calc(var(--dur) * 0.3);
}
.pl__stripe-dot-group:nth-child(1) .pl__stripe-dot:nth-child(4) {
    animation-delay: calc(var(--dur) * 0.45);
}
.pl__stripe-dot-group:nth-child(1) .pl__stripe-dot:nth-child(5) {
    animation-delay: calc(var(--dur) * 0.6833333333);
}
.pl__stripe-dot-group:nth-child(1) .pl__stripe-dot:nth-child(6) {
    animation-delay: calc(var(--dur) * 0.7083333333);
}
.pl__stripe-dot-group:nth-child(1) .pl__stripe-dot:nth-child(7) {
    animation-delay: calc(var(--dur) * 0.8416666667);
}
.pl__stripe-dot-group:nth-child(2) .pl__stripe-dot:nth-child(1) {
    animation-delay: calc(var(--dur) * 0.1083333333);
}
.pl__stripe-dot-group:nth-child(2) .pl__stripe-dot:nth-child(2) {
    animation-delay: calc(var(--dur) * 0.3333333333);
}
.pl__stripe-dot-group:nth-child(2) .pl__stripe-dot:nth-child(3) {
    animation-delay: calc(var(--dur) * 0.425);
}
.pl__stripe-dot-group:nth-child(2) .pl__stripe-dot:nth-child(4) {
    animation-delay: calc(var(--dur) * 0.6);
}
.pl__stripe-dot-group:nth-child(2) .pl__stripe-dot:nth-child(5) {
    animation-delay: calc(var(--dur) * 0.6583333333);
}
.pl__stripe-dot-group:nth-child(2) .pl__stripe-dot:nth-child(6) {
    animation-delay: calc(var(--dur) * 0.8083333333);
}
.pl__stripe-dot-group:nth-child(2) .pl__stripe-dot:nth-child(7) {
    animation-delay: calc(var(--dur) * 0.8416666667);
}
.pl__stripe-dot-group:nth-child(2) .pl__stripe-dot:nth-child(8) {
    animation-delay: calc(var(--dur) * 0.8916666667);
}
.pl__stripe-dot-group:nth-child(3) .pl__stripe-dot:nth-child(1) {
    animation-delay: calc(var(--dur) * -0.0666666667);
}
.pl__stripe-dot-group:nth-child(3) .pl__stripe-dot:nth-child(2) {
    animation-delay: calc(var(--dur) * 0.0583333333);
}
.pl__stripe-dot-group:nth-child(3) .pl__stripe-dot:nth-child(3) {
    animation-delay: calc(var(--dur) * 0.2083333333);
}
.pl__stripe-dot-group:nth-child(3) .pl__stripe-dot:nth-child(4) {
    animation-delay: calc(var(--dur) * 0.4);
}
.pl__stripe-dot-group:nth-child(3) .pl__stripe-dot:nth-child(5) {
    animation-delay: calc(var(--dur) * 0.5166666667);
}
.pl__stripe-dot-group:nth-child(3) .pl__stripe-dot:nth-child(6) {
    animation-delay: calc(var(--dur) * 0.625);
}
.pl__stripe-dot-group:nth-child(3) .pl__stripe-dot:nth-child(7) {
    animation-delay: calc(var(--dur) * 0.7666666667);
}
.pl__stripe-rotate {
    animation-name: stripe-rotate;
}
/* Animation */
@keyframes ball {
    from {
        transform: rotate(0) translate(0, -15.75px);
   }
    to {
        transform: rotate(1turn) translate(0, -15.75px);
   }
}
@keyframes ball-shadow {
    from {
        transform: rotate(0);
   }
    to {
        transform: rotate(-1turn);
   }
}
@keyframes ball-texture {
    from {
        transform: translate(-16px, 0);
   }
    to {
        transform: translate(48px, 0);
   }
}
@keyframes stripe-dot {
    from {
        r: 1.25px;
   }
    16.67%, to {
        r: 0;
   }
}
@keyframes stripe-rotate {
    from {
        transform: rotate(0);
   }
    to {
        transform: rotate(1turn);
   }
}
@keyframes stripe1 {
    from, to {
        stroke-dashoffset: -95.7745;
   }
    50% {
        animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
        stroke-dashoffset: -75.702;
   }
}
@keyframes stripe2 {
    from, to {
        stroke-dashoffset: -80.1;
   }
    50% {
        animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
        stroke-dashoffset: -53.4;
   }
}
@keyframes stripe3 {
    from, to {
        stroke-dashoffset: -72.765;
   }
    50% {
        animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
        stroke-dashoffset: -48.51;
   }
}
