header {
    width: 100vw;
    /* z-index:1000000;
    position: fixed; */
    background: #282c34;
    color: #fff;
}

.header-content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 25px;
}

.back-navigator {
    cursor: pointer;
}